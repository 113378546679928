// column name in data array, data array, list of $refs,
// where ref value == column name
import { request } from "@/components-js/requestSrv";
let jobSort = {};
async function getSortJob() {
  const tmp = await request("/api/job/list", "GET");
  for (const i in tmp) jobSort[tmp[i]["job_name"]] = tmp[i]["job_rate"];
}

getSortJob();

function sortCol(col, arr, refs, oFlag) {
  if (!arr.length) return oFlag;
  oFlag *= -1;
  if (col) {
    arr.sort((a, b) => {
      // check for job sort, it works according to job_rate
      if (jobSort[a[col]] != undefined && jobSort[b[col]] != undefined) {
        if (jobSort[a[col]] < jobSort[b[col]]) return -1 * oFlag;
        if (jobSort[a[col]] > jobSort[b[col]]) return 1 * oFlag;
        return 0;
      }
      // trivial sort
      if (
        isNaN(parseInt(a[col])) ||
        isNaN(parseInt(b[col])) ||
        // compare source and result length
        a[col].toString().length != parseInt(a[col]).toString().length ||
        b[col].toString().length != parseInt(b[col]).toString().length
      ) {
        // text sort
        if (a[col] < b[col]) return -1 * oFlag;
        if (a[col] > b[col]) return 1 * oFlag;
        return 0;
      } else {
        // digital sort
        if (parseInt(a[col]) < parseInt(b[col])) return -1 * oFlag;
        if (parseInt(a[col]) > parseInt(b[col])) return 1 * oFlag;
        return 0;
      }
    });

    for (const tmp in refs) {
      // in some case refs consist of arrays
      let ref = null;
      if (refs[tmp] instanceof Array) ref = refs[tmp][0];
      else ref = refs[tmp];
      if (tmp.toString().indexOf("_sort") != -1) ref.classList.remove("bold");
    }

    let ref = null;
    if (refs[col + "_sort"] instanceof Array) ref = refs[col + "_sort"][0];
    else ref = refs[col + "_sort"];

    ref.classList.add("bold");
  }

  return oFlag;
}

export { sortCol };
